export const statuses = [
  { color: "deep-orange", text: "מחכה לאישור", value: "padding" },
  { color: "red", text: "נדחה", value: "rejected" },
  { color: "green", text: "מאושר", value: "approved" },
  { color: "orange", text: "שומש", value: "used" },
];
export const findStatus = (value) => {
  const result = statuses.find((status) => status.value === value);
  if (!result) throw new Error("Invalid status value: " + value);
  return result.text;
};
export const findStatusColor = (value) => {
  const result = statuses.find((status) => status.value === value);
  if (!result) throw new Error("Invalid status value: " + value);
  return result.color;
};
