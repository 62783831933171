<template>
  <div class="home header-color" v-if="user">
    <my-header
      btnRight="mdi-menu"
      title="הטענת בתי עסק"
      @clickBtnRight="openMenu"
      myClass
    />

    <v-container
      class="form-container d-flex justify-center flex-column body-color"
    >
      <div class="d-flex justify-center">
        <div class="avatar-img d-flex justify-center mt-n16">
          <v-avatar size="126" class="avatar">
            <img src="/person.png" lazy-src="/person.png" />
          </v-avatar>
        </div>
      </div>
      <div class="hllow-user d-flex justify-center" v-if="user">
        שלום,
        <br />
        {{ user.firstName }}
        {{ user.lastName }}
      </div>
      <v-container>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedCompanies"
              :items="companies"
              outlined
              dense
              label="בחר מקומות קניה"
              return-object
              item-text="name"
              @change="addCredit"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="selectedCities"
              :items="cities"
              outlined
              dense
              chips
              small-chips
              label="בחר עיר"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <CreditHandler
          v-if="openCreditModal"
          v-model="openCreditModal"
          :isNew="isNew"
          :credit="chosenCredit"
          :company="chosenCompany"
        />
        <CompanyRow
          v-for="credit in credits"
          :key="credit._id"
          :credit="credit"
          :company="credit.company"
          @openCreditList="openCreditList"
          @removeCompanyFromCredits="removeCompanyFromCredits"
          @edit="editCredit"
          @add="addCredit"
          @addMore="addCredit"
        />
      </v-container>
      <UserCreditListHandler
        v-if="openCreditListModal"
        v-model="openCreditListModal"
        :companyId="currentCompanyId"
      />
    </v-container>

    <InfoDialog v-if="openInfo" v-model="openInfo" />
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
import CompanyRow from "../components/CompanyRow.vue";
import CreditHandler from "../components/dialogs/CreditHandler.vue";
import UserCreditListHandler from "../components/dialogs/UserCreditListHandler.vue";
import InfoDialog from "../components/dialogs/InfoDialog.vue";

export default {
  components: {
    MyHeader,
    CompanyRow,
    CreditHandler,
    UserCreditListHandler,
    InfoDialog,
  },
  name: "UserCredits",
  data() {
    const openInfo = localStorage.getItem("showInfo") === null;
    return {
      currentCompanyId: "",
      openInfo: openInfo,
      openCreditModal: false,
      openCreditListModal: false,
      chosenCredit: null,
      chosenCompany: null,
      isNew: false,
      fieldParameter: [],
      showAlertKidURL: false,
      selectedCompanies: [],
      selectedCities: [],
      cities: [],
    };
  },
  methods: {
    openMenu() {
      this.$store.commit("drawer/set", true);
    },
    openCreditList(id) {
      if (!this.openCreditModal) {
        this.currentCompanyId = id;
        this.openCreditListModal = true;
      }
    },
    addCredit(company) {
      this.isNew = true;
      this.chosenCompany = company;
      this.chosenCredit = null;
      this.openCreditModal = true;
    },
    editCredit(company, credit) {
      this.isNew = false;
      this.chosenCompany = company;
      this.chosenCredit = credit;
      this.openCreditModal = true;
    },
    async removeCompanyFromCredits(id) {
      await this.$store.dispatch("credit/destroy", id);
    },
  },
  computed: {
    companies() {
      return this.$store.getters.companies.filter((c) => {
        let isFiltered = true;
        const credit = this.credits.find((credit) => {
          return credit.company._id === c._id;
        });
        if (credit) isFiltered = false;
        if (
          this.selectedCities.length &&
          !this.selectedCities.find((city) => city === c.city)
        )
          isFiltered = false;
        return isFiltered;
      });
    },
    credits() {
      return this.$store.getters.creditsMerged;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  async beforeMount() {
    if (!this.companies.length) {
      await this.$store.dispatch("company/index");
    }
    await this.$store.dispatch("credit/index");

    this.cities = this.companies.map((a) => a.city);
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 9px !important;
}

.home > .header {
  height: 160px;
}
</style>
