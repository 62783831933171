<template>
  <div>
    <my-dialog
      :title="`רשימת הטענות ב${companyName}`"
      :max-width="500"
      v-model="openModal"
    >
      <template v-slot:content>
        <v-simple-table>
          <template v-slot:default v-if="credits.length">
            <thead>
              <tr>
                <th class="">נטען</th>
                <th class="">שומש</th>
                <th class="">סטטוס</th>
                <th class="text-center">תאריך</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="credit in credits" :key="credit._id">
                <td class="success--text">
                  {{ credit.amount }}
                  <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
                </td>
                <td :class="credit.sumUses ? 'error--text' : ''">
                  {{ credit.sumUses }}
                  <span class="pe-1" v-if="credit.sumUses">-</span>

                  <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
                </td>
                <td :class="`${findStatusColor(credit.status)}--text`">
                  {{ findStatus(credit.status) }}
                </td>

                <td class="text-left">
                  <span>
                    {{ dateTimeFormat(credit.createdAt) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <template v-slot:actions></template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import { findStatus, findStatusColor } from "@/handlers/status";
import { dateTimeFormat } from "@/utils/dayjs";

export default {
  name: "UserCreditListHandler",
  components: { MyDialog },
  props: {
    value: Boolean,
    companyId: { type: String, default: "" },
    isNew: { type: Boolean, default: false },
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    credits() {
      return this.$store.getters.creditsByCompany(this.companyId);
    },
    companyName() {
      return this.credits[0].company.name;
    },
  },
  methods: {
    dateTimeFormat,
    findStatus,
    findStatusColor,
    async submit() {
      if (!this.validateForm()) {
        return this.createErr(""); //TODO: create validation error message
      }
      this.openModal = false;
    },
    validateForm() {
      //TODO: add form validations here
      return true;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
};
</script>
