<template>
  <div>
    <my-dialog
      :title="isNew ? 'יצירת  תשלום' : `עריכת תשלום`"
      v-model="openModal"
      :max-width="500"
    >
      <template v-slot:content>
        <v-row>
          <v-col class="d-flex justify-center align-center">
            <h2>
              {{ company.name }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center align-center">
            <v-text-field
              hide-details
              v-model="amount"
              append-icon="mdi-currency-ils"
              label="סכום מאושר לתשלום"
              outlined
              type="number"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-select
              hide-details
              v-model="type"
              :items="payType"
              item-text="text"
              item-value="value"
              label="סוג תשלום"
              outlined
              type="number"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col
            v-if="company.payLink"
            cols="1"
            class="d-flex justify-center align-center"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :href="company.payLink"
                  target="_blank"
                >
                  <v-icon color="success">mdi-account-credit-card</v-icon>
                </v-btn>
              </template>
              <span>קישור לתשלום</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" v-text="'אישור'" @click="payToCompany" />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import { successSwal, errorSwal } from "@/actions.js";
import { payType } from "@/handlers/payType";

export default {
  name: "credit-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    credit: Object,
    company: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    amount: 0,
    type: "Paybox",
    payType: [...payType],
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async payToCompany() {
      try {
        const actions = this.isNew ? "store" : "update";
        const form = {
          company: this.company?._id ?? this.credit.company,
          amount: this.amount,
          type: this.type,
        };
        if (!this.isNew) {
          form["_id"] = this.credit?._id;
        }
        await this.$store.dispatch(`credit/${actions}`, form);
        this.$emit("removeCompanyFromCredits", this.company._id);
        this.openModal = false;
        successSwal({ title: "הבקשה נשלחה לבית העסק" });
        this.showPayRow = false;
      } catch (e) {
        errorSwal({ title: "אירעה שגיאה" });
      }
    },

    validateForm() {
      //TODO: add form validations here
      return true;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    if (!this.isNew) {
      this.amount = this.credit.amount;
    }
  },
};
</script>
