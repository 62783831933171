<template>
  <v-card
    @click="$emit('openCreditList', credit.company._id)"
    flat
    wrap
    class="ma-5 rounded-xxl"
  >
    <v-row align="center" justify="space-between" class="card veryLightPurple">
      <v-col>
        <v-avatar class="avatar" color="deep-purple lighten-5">
          <img class="pa-1" :src="`/imgs/company.png`" />
        </v-avatar>
      </v-col>
      <v-col>
        <div class="company-name">
          {{ credit.company.name }}
        </div>
      </v-col>
      <v-col>
        <div class="credit-amount" v-if="isPadding">
          <span>
            {{ credit.amount }}
          </span>
          ₪
        </div>

        <div class="credit-amount" v-if="isApproved">
          <span>
            <!-- <p v-if="notUsed">הסכום שנטען:</p> -->
            <span v-if="isPadding">
              {{ credit.amount ?? 0 }}
            </span>
            <span v-else>
              {{
                creditByStatusAndIdMerged.amount -
                creditByStatusAndIdMerged.sumUses
              }}
            </span>
            ₪
          </span>
          <!-- <span v-if="notUsed">
            <br />
            <span>הסכום שנשאר:</span>
            <br />
            <span>
              {{ credit.amount - credit.sumUses }}
            </span>
            ₪
          </span> -->
        </div>
      </v-col>
      <v-col v-if="isPadding" class="d-flex justify-center align-center">
        <div>ממתין לאישור</div>
      </v-col>
      <v-col v-if="isApproved">
        <div class="mx-3">מאושר</div>
      </v-col>
      <v-col v-if="isApproved" class="d-flex justify-center align-center">
        <v-btn
          class="gradient-btn pa-4"
          x-small
          icon
          @click="$emit('addMore', company)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <!-- <v-col
        v-if="!credit.status || isApproved"
        class="d-flex justify-center align-center"
      >
        <v-btn
          class="gradient-btn pa-4"
          x-small
          icon
          @click="$emit('add', company)"
        >
          <v-icon>mdi-contactless-payment-circle-outline</v-icon>
        </v-btn>
      </v-col> -->
      <v-col v-else-if="isPadding" class="d-flex justify-center align-center">
        <v-btn
          class="gradient-btn pa-4"
          x-small
          icon
          @click="$emit('edit', company, credit)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
      <v-col v-else class="d-flex justify-center align-center"></v-col>
    </v-row>
    <!-- <v-row v-if="showPayRow">
      <v-col cols="1" class="d-flex justify-center align-center">
        <v-btn @click="showPayRow = false" color="error" icon>
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-text-field
          hide-details
          v-model="amountApproved"
          append-icon="mdi-currency-ils"
          label="סכום מאושר לתשלום"
          outlined
          type="number"
          @blur="$emit('updateAmount', company._id, amountApproved)"
          @focus="$event.target.select()"
        />
      </v-col>
      <v-col cols="2" class="d-flex justify-start align-center">
        <v-btn
          class="gradient-btn pa-4"
          x-small
          v-text="'אישור'"
          @click="payToCompany"
        />
      </v-col>
    </v-row> -->
  </v-card>
</template>

<script>
import { successSwal, errorSwal } from "@/actions.js";

export default {
  name: "CompanyRow",
  props: {
    company: Object,
    credit: Object,
  },
  data() {
    return { showPayRow: false, amountApproved: 0 };
  },
  computed: {
    creditByStatusAndIdMerged() {
      return this.$store.getters.creditByStatusAndIdMerged(
        "approved",
        this.company._id,
      )[0];
    },
    isPadding() {
      return this.credit.status === "padding";
    },
    isApproved() {
      return this.credit.status === "approved";
    },
    notUsed() {
      const currentAmount = this.credit.amount - this.credit.sumUses;
      return currentAmount == this.credit.amount;
    },
  },
  methods: {
    async payToCompany() {
      try {
        await this.$store.dispatch("credit/store", {
          company: this.company._id,
          amount: this.amountApproved,
        });
        this.$emit("removeCompanyFromCredits", this.company._id);
        successSwal({ title: "הבקשה נשלחה לבית העסק" });
        this.showPayRow = false;
      } catch (e) {
        errorSwal({ title: "אירעה שגיאה" });
      }
    },
  },
};
</script>
